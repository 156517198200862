/* ID Styles */

/* Class Styles */
.spinner-border-custom {
    align-self: center;
    vertical-align: middle;
	margin: 0 5px;
}

.spinner-wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
	color: inherit;
}

.btn-with-loading .spinner-border-custom {
	width: 1.5em;
	height: 1.5em;
}