/* ON/OFF Switch */
.custom-toggle-switch {
  display: inline-block;
  font-size: 80%;
  position: relative;
  width: 55px;
}

.custom-toggle-switch .custom-switch-label {
  border: 1px solid #e6e6e6;
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  cursor: pointer;
  display: block;
  margin: 0;
  overflow: hidden;
}

.custom-toggle-switch .custom-switch-inner {
  margin-left: -100%;
  transition: margin 0.15s ease-in-out;
  -webkit-transition: margin 0.15s ease-in-out;
  -o-transition: margin 0.15s ease-in-out;
  -moz-transition: margin 0.15s ease-in-out;
  width: 200%;
}

.custom-toggle-switch .custom-switch-inner:after,
.custom-toggle-switch .custom-switch-inner:before {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  color: #ffffff;
  float: left;
  font-size: 80%;
  font-weight: normal;
  height: 24px;
  line-height: 24px;
  padding: 0;
  width: 50%;
}

.custom-toggle-switch .custom-switch-inner:after {
  background-color: var(--base-color-grey-dark);
  color: #ffffff;
  content: "Off";
  padding-right: 10px;
  text-align: right;
}

.custom-toggle-switch .custom-switch-inner:before {
  background-color: var(--primary-color);
  color: #ffffff;
  content: "On";
  padding-left: 10px;
}

.disable.custom-toggle-switch .custom-switch-inner:after {
  background-color: var(--base-color-grey-med);
  color: var(--base-color-black);
  font-weight: bold;
}

.disable.custom-toggle-switch .custom-switch-inner:before {
  background-color: var(--base-color-grey-med);
  color: var(--base-color-black); 
  font-weight: bold;
}

.disable.custom-toggle-switch .custom-switch-switch {
  background-color: var(--base-color-grey-med);
}

.custom-toggle-switch .custom-switch-switch {
  background: #ffffff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  bottom: 0;
  height: 20px;
  margin: 0;
  position: absolute;
  right: 32px;
  transition: right 0.15s ease-in-out;
  -webkit-transition: right 0.15s ease-in-out;
  -o-transition: right 0.15s ease-in-out;
  -moz-transition: right 0.15s ease-in-out;
  top: 3px;
  width: 20px;
}

.toggle-group {
	height: 30px;
	padding-top: 2px;
	/* position: relative; */
	top: 50%;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.toggle-group input[type=checkbox] {
  left: 10px;
  /* position: absolute; */
  display: none;
}

.toggle-group input[type=checkbox]:checked ~ .custom-toggle-switch .custom-switch-label .custom-switch-inner {
  margin-left: 0;
}

.toggle-group input[type=checkbox]:checked ~ .custom-toggle-switch .custom-switch-label .custom-switch-switch {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  right: 3px;
}

.toggle-group input[type=checkbox]:focus ~ .custom-toggle-switch {
  outline: thin dotted #333;
  outline: 0;
}

/*Overriding classes for Show/Hide Toggle */
.show-hide-switch.custom-toggle-switch {
  width: 65px;
}

.show-hide-switch.custom-toggle-switch .custom-switch-inner:after {
  content: "Hide";
}

.show-hide-switch.custom-toggle-switch .custom-switch-inner:before {
  content: "Show";
}

.show-hide-switch.custom-toggle-switch .custom-switch-switch {
  right: 42px;
}

/*Overriding classes for Yes/No Toggle */
.yes-no-switch.custom-toggle-switch {
  width: 55px;
}

.yes-no-switch.custom-toggle-switch .custom-switch-inner:after {
  content: "No";
}

.yes-no-switch.custom-toggle-switch .custom-switch-inner:before {
  content: "Yes";
}

.yes-no-switch.custom-toggle-switch .custom-switch-switch {
  right: 32px;
}

.OptionText {
	margin-left: 10px;
	margin-right: 10px;
}