/* MGM Color Swatch */
#root.client-1 {
    --base-color-black: #262626;
    --base-color-grey-dark: #464646;
    --base-color-grey-med: #afafaf;
    --base-color-grey-light: #d6d6d6;
	--base-color-white: #FFFFFF;

    --primary-color: #005EB8;
	--primary-color-gradient: #5BC1E7;
    --secondary-color: #5BC1E7;

    --button-color-accept: #005EB8;
    --button-color-warn: #F3D125;
    --button-color-cancel: #FF0900;
}

/* FBS Color Swatch */
#root.client-2 {
    --base-color-black: #262626;
    --base-color-grey-dark: #464646;
    --base-color-grey-med: #afafaf;
    --base-color-grey-light: #d6d6d6;
	--base-color-white: #FFFFFF;

    --primary-color: #FF9933;
	--primary-color-gradient: #FF9933;
    --secondary-color: #1C75BC;

    --button-color-accept: #1C75BC;
    --button-color-warn: #F3D125;
    --button-color-cancel: #FF0900;
}