/* ID Styles */
#asideLogo {
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 50%;
}

#asideLogoLayout {
	bottom: 3%;
	position: absolute;
	width: calc(100% - 15px);
}

#asideContainer {
	background:linear-gradient(var(--primary-color), var(--primary-color-gradient));
	font-size: .9rem;
	padding-left: 0;
	height:104%;
	padding-right: 0px;
	overflow: hidden;
	position:fixed;
	z-index: 998;
	top: 3em;
	width: 105px;
	max-width: 105px;
	min-width: 105px;
}

#asideContainer.col-md-2 {
	padding-right: 0px;
}

#asideContainer .nav-item > .nav-link-green.active {
	color: var(--base-color-white);
	filter: drop-shadow(0px 3px 6px #00000029);
	background-color: var(--secondary-color);
	font-weight: bold;
	position: relative;
	/* border-left: 10px solid var(--primary-color); */
}

#asideContainer .nav-link {
	padding: 0.1rem 1rem;
}

#asideContainer .nav-link:hover,
#asideContainer .nav-link:focus {
	color: var(--secondary-color);
}

#asideContainer .sub-nav-link {
	color: var(--base-color-grey-med);;
	background-color: var(--base-color-white);
	font-size: .85rem;
	padding: 4px 40px 4px;
}

#asideContainer .sub-nav-link:hover {
	background-color: var(--base-color-white);
	color: #3EB548;
	cursor: pointer;
}

#asideContainer .sub-nav-link.active {
	color: var(--base-color-white);
	text-decoration: underline;
}

#asideContainer .sub-nav-menu {
	background-color: white;
	box-shadow: 0px 3px 6px #00000029;
	padding: 4px 0;
}

#asideContainer a:hover:not(.active) {
	font-weight: bold;
	filter: drop-shadow(0px 3px 6px #00000029);
}

#asideContainer .aside-icon{
	font-size: 2.5em;
	margin-top: .5em;
}

#asideContainer .mdi-icon{
	width: 1.1em;
	height: 1.1em;
}

#asideContainer #supportLink{
	color: var(--base-color-white);
}

@media(min-height: 475px){
	#asideContainer #support{
		text-align: center;
		width: inherit;
		position: fixed;
		bottom: 10px;
	}
}

@media(max-height: 475px){
	#asideContainer #support{
		text-align: center;
		width: inherit;
		position: relative;
		top: 10px;
	}
}

/* Class Styles */