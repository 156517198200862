.LoginWrapper {
	display: flex;
	flex-direction: column;
	flex: 1;
	align-items: center;
	justify-content: center;
	background-color: var(--primary-color);
	width: 100%;
	height: 100%;
}

.LoginContainer {
	max-width: 500px;
	padding: 50px;
	background-color: var(--base-color-white);
}

.LoginContainer #title {
	margin-bottom: 20px;
}

.LoginContainer .form-label {
	margin-bottom: 0;
}

.LoginWrapper > .registrationSupport {
	display: flex;
	justify-content: center;
}

.LoginWrapper > .registrationSupport > .nav-link {
	color: var(--secondary-color);
	text-decoration: underline;
	padding: 0px;
	margin-top: 15px;
}

.RegisterWrapper {
margin-top: 7em;
display: flex;
justify-content: center;
}

@media (pointer: coarse) {
	.RegisterWrapper {
		margin-top: 2em;
		display: flex;
		justify-content: center;
	}
}

.RegisterWrapper > span {
	width: 165px;
}

.RegisterWrapper > span > .nav-link {
	color: var(--secondary-color);
	text-decoration: underline;
	padding: 0px;
}

.RegisterWrapper > #supportLink {
	border-left: 1px var(--base-color-grey-light);
	border-right: 0px;
	border-top: 0px;
	border-bottom: 0px;
	border-style: solid;
}

.RegisterWrapper > #registerLink > a {
	margin-right: 20px;
	margin-left: 20px
}

.RegisterWrapper > #supportLink > a {
	margin-left: 20px;
	margin-right: 60px;
}

.PasswordWrapper { 
	width:100%;
	display: inline-block;
	position: relative;
	margin: 20px 0;
}

.PasswordWrapper > input { 
	width:100%;
	background-color: transparent;
	position: absolute;
}

.password-eye {
	width: 1.2em;
	height: 1.2em;
	cursor: pointer;
	right: 10px;
	top: 39px;
	z-index:9;
	position: absolute;
}

.password-eye > .mdi-icon{
	width: 1.3em;
	height: 1.3em;
}

