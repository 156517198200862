/* Display Table */
.display-table td {
    color: var(--base-color-grey-med);
}

.display-table th {
    background-color: var(--primary-color);
    color: white;
}

.display-table.table-bordered th, 
.display-table.table-bordered td {
    border: none;
}

/* Input Table */


/* Summary Table */