.search-box {
	display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: stretch;
    max-width: 350px;
}

.search-box > div {
	flex: 3;
	margin-right: 5px;
}

.search-box > button {
	flex: 1;
}

.search-results {
	list-style-type: none;
}

.search-results > li {
	width: 33%;
}

.report-box {
	display: none;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	text-align: right;
}