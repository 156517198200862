/* Typography */
form-label{
    font-weight: 500;
    font-size: 20px;
}

h1{
    font-weight: 500;
    font-size: 30px;
}

h2{
    font-weight: 500;
    font-size: 24px;
}

.heading-h1 {
    color: var(--base-color-black);
    font-size: 30px;
    font-weight: bold;
}

.heading-h2 {
    color: var(--base-color-black);
    font-size: 24px;
    font-weight: normal;
}

.heading-h3 {
    color: var(--base-color-black);
    font-size: 20px;
    font-style: italic;
    font-weight: lighter;
}

.heading-h4 {
    color: var(--base-color-black);
    font-size: 20px;
    font-weight: normal;
}

.heading-h5 {
    color: var(--base-color-black);
    font-size: 18px;
    font-weight: normal;
}

.heading-h6 {
    color: var(--base-color-black);
    font-size: 18px;
    font-weight: bold;
}

.heading-h7 {
    color: var(--base-color-black);
    font-size: 18px;
    font-style: italic;
    font-weight: normal;
}

.heading-h8 {
    color: var(--base-color-black);
    font-size: 16px;
    font-weight: normal;
}

.heading-h9 {
    color: var(--base-color-black);
    font-size: 16px;
    font-weight: bold;
}

.heading-h10 {
    color: var(--base-color-black);
    font-size: 12px;
    font-style: italic;
    font-weight: normal;
}