/* ID Styles */
#contentContainer {
	display: flex;
	flex-direction: column;
	height: 100%;
	padding-left: 67px;
	right: 0;
	margin-top: 1em;
	overflow: auto;
	position: relative;
	top: 3em;
	left: 8.33%;
}

#contentContainer .accordion-card-body {
	border-radius: 14px;
	border-color: white;
	color: var(--base-color-black);
	filter: drop-shadow(0px 3px 6px #00000029);
	margin-right: 62px;
	margin-top: 22px;
}

#contentContainer .card-body-summary-info {
	background-color: var(--base-color-white);
	border-radius: 14px;
	border-width: 0;
	color: var(--base-color-black);
	font-size: 16px;
	font-weight: bold;
	margin-top: 10px;
	margin-bottom: 15px;
}

#contentContainer .card-body-summary-info > .card-header {
	background-color: var(--base-color-white);
	border-bottom-color: var(--base-color-white);
	border-bottom-width: 4px;
	border-top-left-radius: 14px;
	border-top-right-radius: 14px;
	text-align: center;
}

#contentContainer .card-body-summary-plan-list {
	border-color: transparent;
	color: var(--primary-color);
	font-size: 16px;
	font-weight: bold;
	margin-left: 25px;
	margin-right: 38px;
}

#contentContainer .card-body-summary-plan-list p {
	border-color: transparent;
	color: var(--base-color-black);
	font-size: 14px;
	font-weight: normal;
}

#wrapper {
	height: calc(100% - 91px);
	margin: 0;
}
