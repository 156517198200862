/* ID Styles */
#root {
    height: 100%;
    width: 100%;
}

/* Class Styles */
.btn:focus {
    box-shadow: none;
    outline: none;
}

.card-title {
    font-size: 18px;
    margin: 0;
    padding-bottom: 3px;
}

.center {
    text-align: center;
}

.collapsing {
    transition: height 75ms;
}

.continue-button {
    background-color: var(--button-color-accept);
    border-radius: 14px;
    border-color: var(--button-color-accept);;
    filter: drop-shadow(0px 3px 6px #00000029);
    font-size: 19.5px;
    height: 51px;
    margin: 7.5px;
    padding: 11.25px;
    width: 115.5px;
}

.dropdown-menu {
    border-radius: 0;
}

.italics {
    font-style: italic;
}

.modal {
    padding-left: 17px;
}

.modal-cancel-button {
    color: var(--base-color-grey-dark);
    font-size: 24px;
    margin: 7.5px 0 15px 0;
    padding: 0 14px 0 0;
    text-align: right;
}

.modal-cancel-button:hover {
    color: var(--base-color-grey-dark);
    text-decoration:none;
}

.modal-close-button {
    color: var(--button-color-accept);
    font-size: 24px;
    margin: 7.5px 0 15px 0;
    padding: 0 14px 0 0;
    text-align: right;
}

.modal-close-button:hover {
    text-decoration:none;
}

.modal-content {
    border-radius: 0;
}

.modal-content-summary .modal-body {
    padding-left: 78px;
    padding-right: 56px;
    padding-top: 49px;
}

.modal-content-summary h3 {
    color: #3EB548;
    font-size: 26px;
}

.modal-content-summary p {
    color: var(--base-color-grey-med);
    font-size: 20px;
    margin-top: 25px;
}

.modal-footer-custom {
    border-top: none;
    padding: 0;
}

.page-content {
    flex: 1;
    margin-bottom: 50px;
}

.plan-detail-subrule {
    padding-left: 5%;
}

.spacer {
    flex-grow: 1;
}

.summary-info-data {
    font-size: 14px;
    padding-bottom: 30px;
}

.summary-info-data-label {
    font-weight: bold;
}

.summary-info-data-link {
    font-weight: normal;
}

.summary-info-data-text {
    font-weight: normal;
}

.summary-info-section-header {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-emphasis: underline;
}

.table-sm-plan-details td,
.table-sm-plan-details th {
    padding-bottom: 0;
    padding-top: 0;
}

.table-striped thead tr,
.table-striped tbody tr:nth-of-type(even) {
    background-color: var(--base-color-white);
    cursor: pointer;
}

.text-grey-small {
    font-size: 12px;
}

.z-index-1 {
    z-index: 1;
}

.z-index-2 {
    z-index: 2;
}

.z-index-3 {
    z-index: 3;
}

.z-index-4 {
    z-index: 4;
}

/* Tag Styles */
a.nav-link-green {
    color: var(--base-color-white);
    text-align: center;
}

body,
html {
    font-family: 'Roboto', sans-serif;
    height: 100%;
    width: 100%;
}

body {
    margin: 0;
}

input:focus {
    outline: none;   
}

::-webkit-scrollbar {
    width: 1.8em;
}

::-webkit-scrollbar-thumb {
    background-color: var(--base-color-white);
    border-right: 8px solid white;
}

::-webkit-scrollbar-track {
    margin: 8px 0;
}

input::-ms-reveal,
      input::-ms-clear {
        display: none;
      }

/* Media Queries */
@media (min-width: 576px) {
    .modal-dialog {
        max-width: 606px;
    }

    
}

@media (min-width: 1050px){
    #MainNavigationItems .navbar-collapse{
        display: none !important;
    }

    .navbar-brand{
        margin: 0 auto !important;
    }
    #MainNavigation {
        justify-content: space-between !important;
    }

    .BrandWrapper{
        width: 100%;
    }
}

@media(min-width: 1050px){
    .navbar-toggler{
        display:none;
    }

    #MainNavigationItems {
        display:none !important;
    }
}

@media (max-width: 1050px){
    #asideContainer{
        display: none;
    }

    #contentContainer,
    #header {
        padding-left: 5px !important;
        padding-right:5px;
        flex-basis: 100%;
        max-width: 100%;
        left: 0% !important;
    }

    .InformationContainer,
    .PreviousResults,
    .BeginSurvey,
    .Announcements,
    .QuestionWrapper,
    .QuestionnaireResultWrapper,
    .LoginWrapper,
    .welcomeWrapper,
    .ProfileWrapper
    {
        max-width: 350px !important;
        margin-left: auto;
        margin-right: auto;
    }

    #root::-webkit-scrollbar {
        display: none;
      }
    
}

div.form-buttons {
	flex-direction: row;
    display: flex;
    justify-content: space-between;
}