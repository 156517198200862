.user-item {
	border: 1px solid var(--base-color-grey-med);
	border-radius: 10px;
	padding: 15px;
	min-width: 250px;
	margin-bottom: 5px;
	cursor: pointer;
}

.user-item.selected-item {
	border: 1px solid var(--primary-color);
	box-shadow: inset 0 0 10px var(--primary-color);
}

.push-tag {
	color: var(--secondary-color);
	font-size: smaller;
	float: right;
}

.admin-tag {
	color: var(--button-color-cancel);
	font-size: smaller;
	float: right;
}