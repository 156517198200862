#topHeader{
	width: 100%;
	height: 3em;
	margin-left: 0px;
	margin-right: 0px;
	
	display: flex;
	justify-content: center;
	align-items: center;
}

#MainNavigation{
	height:3em;
	justify-content: space-between;
	border-bottom:1px solid var(--base-color-grey-light);
	align-items: center;
	align-content: center;
	position:fixed;
	width:100%;
	background-color: white;
	z-index: 997;
}

.navbar-brand{
	display:flex;
	flex-direction: row;
	justify-content: space-between;
	/* width:60%; */
	margin-right: 15px;
}

.BrandWrapper{
	display:flex;
	flex-direction: row;
	justify-content: space-between;
	width:100%;
	margin-bottom: -5px;
}

#MainNavigation>button{
	margin-top:3px;
	border:0px;
	padding-left: 0px;
	margin-left:-5px;
}

#MainNavigationItems{
	position: absolute;
	margin-top: 3em;
	background-color: white;
	z-index: 999;
	top:0px;
}

#MainNavigationItems .nav-link{
	font-size:1.5em;
}

#MainNavigationItems #supportLink{
	border-top: 1px solid gainsboro;
	margin-top: 7px;
	padding-top: 15px;
}