.push-notification-group {
	border: 1px solid var(--base-color-grey-med);
	border-radius: 10px;
	padding: 15px;
	min-width: 250px;
	margin-bottom: 5px;
	cursor: pointer;
}

.push-notification-group.selected-group {
	border: 1px solid var(--primary-color);
	box-shadow: inset 0 0 10px var(--primary-color);
}