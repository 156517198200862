.group-detail-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.enrollment-url {
	min-width: 500px;
}

.logo-preview {
	height: 100px;
	margin-bottom: 20px;
	position: absolute;
    right: 20px;
    top: 20px;
}