/* NOTE: SHOULD PROBABLY HAVE THE ACTIVE CLASSES DEFINED IN THE STYLE GUIDE ALSO */


/* Square Rounded */
.btn-squoval {
    border-radius: 14px;
    box-shadow: 0px 3px 6px var(--base-color-black);
    font-size: 24px;
    height: 50px;
    width: 115px;
}

.btn-squoval-primary-blue {
    background-color: var(--primary-color);
    color: white;
}

.btn-squovall-secondary-blue {

}

.btn-squoval-primary-blue-outline {

}

.btn-squoval-secondary-blue-outline {

}

.btn-squoval-secondary-red {

}

.btn-squoval-secondary-red-outline {

}

/* Rounded (Pills) */
.btn-pill {
    border-radius: 2rem;
    font-size: 12px;
    height: 29px;
    width: 120px;
}

.btn-pill-primary-blue {
    background-color: var(--primary-color);
    color: white;
}

.btn-pill-secondary-blue {
    background-color: var(--button-color-accept);
    color: white;
}

.btn-pill-primary-blue-outline {
    background-color: white;
    border-color: var(--primary-color);
    color: var(--primary-color);
}

.btn-pill-secondary-blue-outline {
    background-color: white;
    border-color: var(--button-color-accept);
    color: var(--button-color-accept);
}

.btn-pill-secondary-red {
    background-color: var(--button-color-cancel);
    color: white;
}

.btn-pill-secondary-red-outline {
    background-color: white;
    border-color: var(--button-color-cancel);
    color: var(--button-color-cancel);
}

.btn-with-loading {
	display: flex !important;
    flex-direction: row;
    align-items: center;
}

.btn-outline-primary {
	color: var(--secondary-color) !important;
	border-color: var(--secondary-color) !important;
}

.btn-outline-primary:hover {
	color: var(--base-color-white) !important;
	background-color: var(--secondary-color) !important;
}

.btn-outline-danger {
	color: var(--button-color-cancel) !important;
	border-color: var(--button-color-cancel) !important;
}

.btn-outline-danger:hover {
	color: var(--base-color-white) !important;
	background-color: var(--button-color-cancel) !important;
}

.btn-right {
	float: right;
}

/* Unstyled Links (Large) */


/* Unstyled Links (small) */


/* Standard Links */